import { Dispatch, SetStateAction } from 'react';
import { Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { blockedPlayersEnumStatusMap, EBlockedPlayerStatus } from 'views/Players/Players.types';
import { FilterType } from 'components/FilterBar/types';
import { capitalizeFirstLetter } from 'utils/formattingUtil';

const blockedPlayersColumns = [
  {
    field: 'playerId',
    minWidth: 200,
    headerName: 'Player ID',
    disableReorder: true,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Stack
         direction="row"
         gap={1}
         justifyContent="center"
         alignItems="center"
       >
         <span>{params.row.playerId}</span>
       </Stack>
     );
   }
  },
  {
    field: 'blockDate',
    minWidth: 100,
    headerName: 'Block Date',
    disableReorder: true,
    flex: 1,
    valueGetter: (params: any) => {
      return dayjs(params.row.banDate).utc().format('DD/MM/YYYY');
    }
  },
  {
    field: 'endDate',
    minWidth: 100,
    headerName: 'Blocked Until',
    disableReorder: true,
    flex: 1,
    valueGetter: (params: any) => {
      return dayjs(params.row.bannedUntil).utc().format('DD/MM/YYYY');
    }
  },
  {
    field: 'reason',
    minWidth: 200,
    headerName: 'Reason',
    disableReorder: true,
    flex: 1,
    renderCell: (params: any) => {
      return (
        <Stack
          direction="row"
          gap={1}
          justifyContent="center"
          alignItems="center"
        >
          <span>{params.row.banReason}</span>
        </Stack>
      );
    }
  },
  {
   field: 'status',
   minWidth: 100,
   headerName: 'Status',
   disableReorder: true,
   flex: 1,
     renderCell: (params: any) => {
       const statusData = params.row.isBlocked ? blockedPlayersEnumStatusMap.blocked: blockedPlayersEnumStatusMap.unblocked;
       return (
         <StatusLabel
           text={statusData.text}
           status={statusData.status}
           prefixIcon={statusData.prefixIcon}
         />
       )
     },
     valueGetter: (params: any) => {
       return params.row.isBlocked ? 'Blocked' : 'Unblocked';
     },
     sortComparator: (v1: string, v2: string) => {
       return v1.localeCompare(v2);
     },
  }
];

const getBlockedPlayersFilters = (
  setRangeDates: (dates: ([Dayjs, Dayjs] | undefined)) => void,
  statusFilterValues: string[],
  setStatusFilterValues: Dispatch<SetStateAction<string[]>>,
  setReasonFilterValue: Dispatch<SetStateAction<string>>,
  reasonFilterValue: string,
  rangeDates?: [Dayjs, Dayjs],
) => {
  return ([
    {
      type: FilterType.SEARCH_INPUT,
      header: "Reason",
      onChange: (e: any) => setReasonFilterValue(e.target.value),
      value: reasonFilterValue,
      setValue: setReasonFilterValue
    },
    {
      type: FilterType.DATE_RANGE,
      rangeDates,
      setRangeDates
    },
    {
      type: FilterType.SELECT,
      header: "Status",
      name: "status",
      items: [
        {
          content: capitalizeFirstLetter(EBlockedPlayerStatus.BLOCKED),
          key: '0',
          value: 0,
          filterBy: EBlockedPlayerStatus.BLOCKED
        },
        {
          content: capitalizeFirstLetter(EBlockedPlayerStatus.UNBLOCKED),
          key: '1',
          value: 1,
          filterBy: EBlockedPlayerStatus.UNBLOCKED
        }
      ],
      isCheckBox: true,
      renderStatusTextOverride: (status: string | string[]) => {
        return (
          <span>
             {status[0].charAt(0).toUpperCase() + status[0].slice(1)}
            {status.length > 1 && ` and ${status.length - 1} more`}
          </span>
        );
      },
      value: statusFilterValues,
      setSelectedItems: (selectedItems: string[]) => setStatusFilterValues(selectedItems),
    }
  ])
};

export const blockedPlayersUtils = {
  blockedPlayersColumns,
  getBlockedPlayersFilters
}
