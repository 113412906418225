import { Route, Routes, useNavigate } from 'react-router-dom';
import PrivateRoute from 'utils/privateRoute';
import { ErrorBoundary } from 'react-error-boundary';
import BackOffice from 'pages/BackOffice/BackOffice';
import { ErrorFallback } from '@appcharge/shared-ui';
import LoginPage from 'pages/Login/Login';
import BackOfficeRoutes from './BackOfficeRoutes';
import ProjectsView from '../views/Projects/ProjectsView';
import ReportsView from '../views/Reports/ReportsView';
import FinancialReportsTable from '../views/Reports/FinancialReports';
import PayoutsTable from '../views/Reports/Payouts';
import ProfileView from '../views/Profile/ProfileView';
import OrdersView from '../views/Orders/OrdersView';
import OrderDetails from '../views/Orders/OrderDetails';
import FreeOrdersTable from 'components/Orders/FreeOrdersTable/FreeOrdersTable';
import OrdersTable from 'components/Orders/OrdersTable/OrdersTable';
import PersonalInfo from '../components/PersonalInfo/PersonalInfo';
import PasswordTab from '../components/PasswordsTab/PasswordTab';


const AppRoutes = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/project');
  };
  return (
    <Routes>
      <Route
        path="/project/*"
        element={
          <PrivateRoute>
             <ErrorBoundary FallbackComponent={() => <ErrorFallback handleClick={handleNavigate} />}>
                <BackOffice />
             </ErrorBoundary>
          </PrivateRoute>
      }
    >
      <Route index element={<ProjectsView />} />
      <Route path="orders" element={<OrdersView />}>
        <Route index element={<OrdersTable />} />
        <Route path="free" element={<FreeOrdersTable />} />
      </Route>
      <Route path="orders/:orderId" element={<OrderDetails isFree={false} />} />
      <Route path="orders/free/:orderId" element={<OrderDetails isFree={true} />} />
      <Route path="reports" element={<ReportsView />}>
        <Route index path="financialreports" element={<FinancialReportsTable />} />
        <Route path="payouts" element={<PayoutsTable />} />
      </Route>
      <Route path="profile" element={<ProfileView />}>
        <Route path="personal" element={<PersonalInfo />} />
        <Route path="password" element={<PasswordTab />} />
      </Route>
      {BackOfficeRoutes}
    </Route>

    <Route path="/:error?" element={<LoginPage />} />
    </Routes>
  )
};

export default AppRoutes;
