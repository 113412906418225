import { FC, SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageTopBar from 'components/Topbar/PageTopBar';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { permissionsUtil } from 'utils/permissionsUtil';
import { AuthSliceState } from 'store/store.types';
import usePricing from 'api/usePricing';

enum EPricingFormState {
  PRICING_POINTS = 'pricingPoints',
  SETTINGS = 'pricingSettings'
}

export const PricingView: FC = () => {
  const navigate = useNavigate();
  const currentPublisherId = useSelector(
      ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { getPricings } = usePricing(currentPublisherId);
  const [tab, setTab] = useState(EPricingFormState.PRICING_POINTS);

  const handleTabChange = (event:  SyntheticEvent, newValue: EPricingFormState) => {
    setTab(newValue);
    const newPath = `/project/${currentPublisherId}/pricing/${newValue}`;
    navigate(newPath);
  }

  const isSuperAdminUser = currentPublisherId ?
    permissionsUtil.isSuperAdminByProject(currentPublisherId) :
    false;

  const tabs = [{label: "Price Points", value: EPricingFormState.PRICING_POINTS}];

  if (isSuperAdminUser) {
    tabs.push({label: "Settings", value: EPricingFormState.SETTINGS})
  }

  return (
    <TabsPanel
      tabs={tabs}
      headerComponent = {
        <PageTopBar
          withTabsDesign={true}
          disable={false}
          headline="Pricing"
          buttons={[
            {
              text: 'Create New Pricing',
              action: () => navigate('./form'),
              disabled:
                !permissionsUtil.canUserEdit() || getPricings.isLoading,
              hidden:
                !permissionsUtil.isActionEnabled(
                  null,
                  EPermissionGroup.PRICING,
                  null,
                  EPermissionAction.MANAGE
                )
            }
          ]}
        />
      }
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  );
};
