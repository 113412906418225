import { useSelector } from 'react-redux';

import { AxiosError } from 'axios';

import useImages from '../api/useImages';
import { EAssetType, ENotificationType } from '../constants/enums';
import { AuthSliceState } from '../store/store.types';

import { useNotifications } from './useNotifications';

export const UploadsTooltips = {
  [EAssetType.PRODUCT]: '1:1 Ratio | PNG, JPEG | At least 128px width',
  [EAssetType.BADGE]: '1:1 Ratio | PNG, JPEG | At least 128px width',
  [EAssetType.BG_BUNDLE]: '1:2 Ratio | PNG, JPEG | 320px width minimum',
  [EAssetType.BG_DESKTOP]: '1920 X 1080 px | PNG, JPEG',
  [EAssetType.BG_MOBILE]: '1:3 Ratio | PNG, JPEG | At least 400px width',
  [EAssetType.BG_POPUP]: '1:1 Ratio | PNG, JPEG | At least 400px width',
  [EAssetType.FAVICON]: '1:1 Ratio | PNG, JPEG | 32px width',
  [EAssetType.LOGO]: '1:1 Ratio | PNG, JPEG | 320px width',
  [EAssetType.PRODUCT_PREFIX]: '1:1 Ratio | PNG, JPEG | At least 32px width',
  [EAssetType.GENERAL]: '1:1 Ratio | PNG, JPEG | 128px width',
  [EAssetType.SECTION]: '1:1 Ratio | PNG, JPEG | 128px width',
  [EAssetType.BANNER]: '1:3 Ratio | PNG, JPEG | At least 400px width',
  BG_BUNDLE_LINEAR: '2:1 Ratio | PNG, JPEG | At least 400px width',
  BG_BUNDLE_TWO_IN_ONE: '1:1 Ratio | PNG, JPEG | At least 400px width',
  BG_BUNDLE_THREE_IN_ONE: '2:1 Ratio | PNG, JPEG | At least 400px width',
  BG_BUNDLE_SIX_IN_ONE: '2:1 Ratio | PNG, JPEG | At least 400px width',
  BG_SPECIAL_OFFER: '2:1 Ratio | PNG, JPEG | At least 400px width',
  BG_POPUP_SQUARE: '1:1 Ratio | PNG, JPEG | At least 400px width',
  BG_POPUP_TWO_ON_THREE: '2:3 Ratio | PNG, JPEG | At least 400px width'
};

export const UploadTooltipsDescription =
  'For optimized display, make sure your image is at least';

export const useUploads = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const { addImage } = useImages(currentPublisherId);
  const { enqueueSnackbar } = useNotifications();

  const uploadImage = async (
    event: React.SyntheticEvent,
    pictureRef: HTMLInputElement,
    pictures: any,
    setFieldValue: Function,
    setCurrentUploadingField: Function,
    currentUploadingField: string
  ) => {
    event.preventDefault();
    const file = pictureRef.files?.[0];
    const formData = new FormData();
    if (!file) {
      return;
    }
    if (file && file.size > 2000000) {
      enqueueSnackbar(`File size limit is 2MB`, ENotificationType.ERROR);
      return;
    }
    formData.append('image', file as File);
    formData.append('type', 'Products');
    formData.append('name', file.name);
    addImage.mutate(formData, {
      onSuccess: async (data) => {
        enqueueSnackbar(
          `${data!.data.name} has been uploaded`,
          ENotificationType.SUCCESS
        );
        await pictures.refetch();
        setFieldValue(currentUploadingField, data!.data.url);
        setCurrentUploadingField('');
      },
      onError(error) {
        console.log({ error });
        enqueueSnackbar(
          `Asset has failed to upload`,
          ENotificationType.ERROR,
          (
            (error as AxiosError).response?.data as {
              message: string;
            }
          ).message
        );
      }
    });
  };

  return { uploadImage };
};
