import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { EPublisherSolutionModel } from 'common/contracts';
import PageTopBar from 'components/Topbar/PageTopBar';
import { ELocalStorageKeys } from 'constants/enums';
import { localStorageUtil } from 'utils/localStorageUtil';

import '../../components/Orders/style.scss';

enum EOrdersState {
  ORDERS = 'orders',
  FREE_ORDERS = 'freeOrders'
}

const OrdersView = () => {
  const isCheckoutUser =
    localStorageUtil.get(ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL) ===
    EPublisherSolutionModel.CHECKOUT;
  const navigate = useNavigate();
  const location = useLocation();
  const basePath = location.pathname.split('/orders')[0];
  const currentTab = location.pathname.includes('/free')
    ? EOrdersState.FREE_ORDERS
    : EOrdersState.ORDERS;
  const [tab, setTab] = useState(currentTab);

  const handleTabChange = (
    event: any,
    newValue: EOrdersState | ((prevState: EOrdersState) => EOrdersState)
  ) => {
    setTab(newValue);
    const newPath =
      newValue === EOrdersState.ORDERS
        ? `${basePath}/orders`
        : `${basePath}/orders/free`;
    navigate(newPath, { state: { isInternalLink: true } });
  };

  useEffect(() => {
    if (currentTab !== tab) {
      setTab(currentTab);
    }
  }, [currentTab, tab]);

  const tabs = [{label: "Orders", value: EOrdersState.ORDERS}];

  if (!isCheckoutUser) tabs.push({label: "Free Orders", value: EOrdersState.FREE_ORDERS})

  return (
    <TabsPanel
      tabs={tabs}
      headerComponent = {<PageTopBar withTabsDesign={true} headline="Orders" />}
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  );
};

export default OrdersView;
