import { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageTopBar from 'components/Topbar/PageTopBar';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import { AuthSliceState } from 'store/store.types';

enum EPlayersState {
    BLOCKED = 'blocked'
}

export const PlayersView = () => {
  const navigate = useNavigate();
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const [tab, setTab] = useState(EPlayersState.BLOCKED);

  const handleTabChange = (event:  SyntheticEvent, newValue: EPlayersState) => {
    setTab(newValue);
    const newPath = `/project/${currentPublisherId}/players/${newValue}`;
    navigate(newPath);
  }

  const tabs = [{label: "Blocked players", value: EPlayersState.BLOCKED}];

  return (
    <TabsPanel
      tabs={tabs}
      headerComponent = {<PageTopBar withTabsDesign={true} headline="Players" />}
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  )
}
