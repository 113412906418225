import { permissionsUtil } from 'utils/permissionsUtil';
import { EPermissionGroup, IPermissions } from 'common/permissions.types';
import { ESettingsState } from './Settings.types';

const getActiveSettingsTab = (permissions: IPermissions | undefined, id: string | undefined, isCheckoutProject: boolean | undefined) => {
  return  permissionsUtil.shouldHideGroupInPermission(
    permissions,
    EPermissionGroup.COMPANY,
    id
  ) ?
    ESettingsState.COMPANY
    : permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.INTEGRATION,
      id
    ) ?
      ESettingsState.INTEGRATION
      : permissionsUtil.shouldHideGroupInPermission(
        permissions,
        EPermissionGroup.USERS,
        id
      ) ?
        ESettingsState.USERS
        : !isCheckoutProject ||
           permissionsUtil.shouldHideGroupInPermission(
             permissions,
             EPermissionGroup.AUTHENTICATION,
             id
        ) ?
          ESettingsState.PLAYER_AUTH
          : ESettingsState.COMPANY
}

export const settingsTabUtils = {
  getActiveSettingsTab
}
