import { useState } from 'react';

import { Offer } from '../common/contracts';
import { Product } from '../components/ProductsForm/ProductsForm.types';

export enum PublisherIdType {
  OFFER_ID = 'publisherOfferId',
  PRODUCT_ID = 'publisherProductId'
}

export enum SearchKeyType {
  NAME = 'name'
}

type IDataType = Product | Offer;

export type IUseTableSearchFiltersProps = {
  data: IDataType[];
  publisherId: PublisherIdType;
  searchKey: SearchKeyType;
  additionalPromotionFilter?: (item: IDataType) => boolean;
};

export const useTableSearch = ({
  data,
  publisherId,
  searchKey,
  additionalPromotionFilter
}: IUseTableSearchFiltersProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [skuValue, setSkuValue] = useState('');

  const searchByValue = (item: IDataType): boolean => {
    return (item as any)[searchKey]
      .toLowerCase()
      .includes(searchValue.toLowerCase());
  };

  const filterBySku = (item: IDataType): boolean => {
    return (item as any)[publisherId]
      ?.toLowerCase()
      .includes(skuValue.toLowerCase());
  };

  const filteredData = () => {
    if (data && data.length > 0) {
      return data.filter((item) => {
        if (additionalPromotionFilter && !additionalPromotionFilter(item)) {
          return false;
        }

        return searchByValue(item) && filterBySku(item);
      });
    }

    return [];
  };

  return { searchValue, skuValue, setSearchValue, setSkuValue, filteredData };
};
