import React from 'react';

import { Box } from '@mui/system';

import AcInput from '../AcInput/AcInput';
import AcSearchInput from '../AcInput/AcSearchInput';

export type ITableSearchFiltersProps = {
  searchValue: string;
  skuValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setSkuValue: React.Dispatch<React.SetStateAction<string>>;
  tableVariant: string;
};

export const TableSearchFilters = ({
  searchValue,
  skuValue,
  setSearchValue,
  setSkuValue,
  tableVariant
}: ITableSearchFiltersProps) => {
  return (
    <>
      <AcInput
        placeholder={`Search by ${tableVariant} name`}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        sx={{
          maxWidth: '340px',
          marginTop: '16px'
        }}
      />
      <Box mt={2} width="max-content">
        <AcSearchInput
          size="small"
          header={`${tableVariant[0].toUpperCase() + tableVariant.slice(1)} SKU`}
          onChange={(e: any) => setSkuValue(e.target.value)}
          value={skuValue}
          setValue={setSkuValue}
          renderStatusTextOverride={(value: string) => value}
        />
      </Box>
    </>
  );
};
