import { useEffect, useRef, useState } from 'react';

import { Drawer, Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import usePayouts from 'api/usePayouts';
import usePermissions from 'api/usePermissions';
import dayjs, { Dayjs } from 'dayjs';
import { permissionsUtil } from 'utils/permissionsUtil';

import AcInput from 'components/AcInput/AcInput';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import ActionButton from 'components/ActionButton/ActionButton';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import DataTable from 'components/DataTable/DataTable';
import DialogModal from 'components/Dialog/Dialog';
import FirstActionModal from 'components/FirstActionModal/FirstActionModal';
import {
  EButtonColor,
  ENotificationType,
  SortingDirection
} from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';

import { EPayoutStatus } from './Report.types';
import { getPayoutsColumns } from './utils';

import './style.scss';

const PayoutsTable = () => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState<string | null>();
  const [transferDateValue, setTransferDateValue] = useState<Dayjs | null>(
    dayjs()
  );
  const [fromDateValue, setFromDateValue] = useState<Dayjs | null>(dayjs());
  const [toDateValue, setToDateValue] = useState<Dayjs | null>(
    dayjs().add(1, 'day')
  );
  const [transferReference, setTransferReference] = useState<string>('');
  const [transferAmount, setTransferAmount] = useState<string>('');
  const [transferCurrency, setTransferCurrency] = useState<string>('USD');
  const [reportFile, setReportFile] = useState<File | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { enqueueSnackbar } = useNotifications();
  const fileRef = useRef<HTMLInputElement>(null);
  const { getPermissions } = usePermissions();
  const permissions = getPermissions();

  const {
    getPayouts,
    deletePayout,
    createPayout,
    getPayoutDownload,
    getPayoutDownloadNewVersion,
    updatePayout
  } = usePayouts(
    permissions
      ? permissionsUtil.getPublishersByPayouts(permissions).join(',')
      : undefined
  );

  const onDownloadClick = (
    payoutId: string,
    publisherId?: string | undefined,
    period?: string | undefined
  ) => {
    if (publisherId && period) {
      getPayoutDownloadNewVersion(payoutId, publisherId, period);
    }
  };

  const handleEditAction = (params: any) => {
    setSelectedPayout(params.row._id);
    setTransferDateValue(dayjs(params.row.transferDate).utc());
    setFromDateValue(dayjs(params.row.period.startDate).utc());
    setToDateValue(dayjs(params.row.period.endDate).utc());
    setTransferAmount(params.row.amount);
    setTransferCurrency(params.row.currency);
    setTransferReference(params.row.reference);
    setIsEditMode(true);
    setIsDrawerOpen(true);
  };

  const handleDeleteAction = (params: any) => {
    setDeleteDialogOpen(true);
    setSelectedPayout(params.row._id);
  };

  const resetForm = () => {
    setTransferDateValue(dayjs());
    setFromDateValue(dayjs());
    setToDateValue(dayjs().add(1, 'day'));
    setTransferReference('');
    setTransferAmount('');
    setTransferCurrency('USD');
    setReportFile(null);
  };

  const createNewPayout = (): void => {
    const payoutData = new FormData();
    payoutData.append('transferDate', transferDateValue!.toISOString());
    payoutData.append('amount', transferAmount.toString());
    payoutData.append('currency', transferCurrency);
    payoutData.append(
      'period[startDate]',
      fromDateValue!.utcOffset(fromDateValue!.utcOffset() || 0).toISOString()
    );
    payoutData.append(
      'period[endDate]',
      toDateValue!.utcOffset(toDateValue!.utcOffset() || 0).toISOString()
    );
    payoutData.append('status', EPayoutStatus.SUCCESS);
    payoutData.append('reference', transferReference);
    payoutData.append('payoutFile', reportFile as File);
    createPayout.mutate(payoutData as any, {
      onSuccess: () => {
        getPayouts.refetch();
        enqueueSnackbar(
          'Your payout submission was successful.',
          ENotificationType.SUCCESS
        );
        setIsDrawerOpen(false);
        setIsEditMode(false);
        resetForm();
      },
      onError: () => {
        enqueueSnackbar(
          'Payout creation failed. Please try again later.',
          ENotificationType.ERROR
        );
      }
    });
  };

  const updateExistingPayout = (): void => {
    const payoutData = new FormData();
    payoutData.append('transferDate', transferDateValue!.toISOString());
    payoutData.append('amount', transferAmount.toString());
    payoutData.append('currency', transferCurrency);
    payoutData.append(
      'period[startDate]',
      fromDateValue!.utcOffset(fromDateValue!.utcOffset() || 0).toISOString()
    );
    payoutData.append(
      'period[endDate]',
      toDateValue!.utcOffset(toDateValue!.utcOffset() || 0).toISOString()
    );
    payoutData.append('status', EPayoutStatus.SUCCESS);
    payoutData.append('reference', transferReference);
    reportFile && payoutData.append('payoutFile', reportFile as File);
    updatePayout.mutate(
      { id: selectedPayout!, newPayout: payoutData as any },
      {
        onSuccess: () => {
          getPayouts.refetch();
          enqueueSnackbar(
            'Your payout submission was successful.',
            ENotificationType.SUCCESS
          );
          setIsDrawerOpen(false);
          setIsEditMode(false);
          resetForm();
        },
        onError: () => {
          enqueueSnackbar(
            'Payout creation failed. Please try again later.',
            ENotificationType.ERROR
          );
        }
      }
    );
  };

  const deletePayoutHandler = () => {
    if (selectedPayout)
      deletePayout.mutate(selectedPayout, {
        onSuccess: () => {
          getPayouts.refetch();
          enqueueSnackbar(
            'Payout has been deleted successfully!',
            ENotificationType.SUCCESS
          );
          setIsDrawerOpen(false);
          resetForm();
        },
        onError: (err: any) => {
          console.error(err);
          enqueueSnackbar(
            `Couldn't delete payout ${selectedPayout}, please try again soon`,
            ENotificationType.ERROR
          );
        }
      });
  };

  useEffect(() => {
    getPayouts.refetch();
  }, []);

  const getPayoutsRowsData = () => {
    if (!getPayouts.data) return [];
    const rowsData = getPayouts.data.map((payout: any) => {
      return { _id: payout.id, ...payout };
    });
    return rowsData;
  };

  return (
    <AcViewWrapper
      header={null}
    >
      <AcContentWrapper>
        <div className="list">
          <DataTable
            columns={getPayoutsColumns(
              (payoutId, publisherId, period) =>
                onDownloadClick(payoutId, publisherId, period)
            )}
            rows={getPayoutsRowsData()}
            defaultHiddenFields={[
              !permissionsUtil.isSuperAdmin() ? 'Actions' : ''
            ]}
            rowIdIdentifier={'_id'}
            loading={getPayouts.isLoading}
            error={getPayouts.isError}
            onNoData={
              <FirstActionModal
                headline="No reports to display"
                text="Create a new report to display it here"
              />
            }
            className="payouts-table"
            initialSorting={{
              sortModel: [
                {
                  field: 'transferDate',
                  sort: SortingDirection.DESC
                }
              ]
            }}
          />
        </div>
      </AcContentWrapper>
      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete Payout Report"
        text="Are you sure you want to delete this payout?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: () => {
              deletePayoutHandler();
              setDeleteDialogOpen(false);
            }
          }
        ]}
        closeDialog={() => setDeleteDialogOpen(false)}
      />
      <Drawer
        PaperProps={{
          sx: { width: '400px' }
        }}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setIsEditMode(false);
          resetForm();
        }}
        anchor={'right'}
      >
        <Stack p={4} pt={2} gap={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography variant="h6" gutterBottom mb={0}>
              {isEditMode ? 'Edit' : 'Add New'} Payout Report
            </Typography>
            <DatePicker
              format="DD/MM/YYYY"
              label="Transfer date"
              value={transferDateValue}
              onChange={(newValue) => {
                return (
                  newValue &&
                  setTransferDateValue(
                    newValue?.utc().hour(0).minute(0).second(0)
                  )
                );
              }}
            />
            <DatePicker
              format="DD/MM/YYYY"
              label="From"
              value={fromDateValue}
              onChange={(newValue) => {
                return (
                  newValue &&
                  setFromDateValue(newValue?.utc().hour(0).minute(0).second(0))
                );
              }}
            />
            <DatePicker
              format="DD/MM/YYYY"
              label="To"
              value={toDateValue}
              onChange={(newValue) => {
                return (
                  newValue &&
                  setToDateValue(newValue?.utc().hour(0).minute(0).second(0))
                );
              }}
            />
            <AcInput
              value={transferCurrency}
              onChange={(e) => setTransferCurrency(e.target.value)}
              size="small"
              header="Currency"
            />
            <AcInput
              value={transferAmount}
              onChange={(e) => setTransferAmount(e.target.value)}
              size="small"
              header="Amount"
            />
            <AcInput
              value={transferReference}
              onChange={(e) => setTransferReference(e.target.value)}
              size="small"
              header="Reference number"
            />
            <Stack gap={2}>
              <input
                type="file"
                ref={fileRef}
                onChange={() => {
                  fileRef.current?.files &&
                    setReportFile(fileRef.current?.files[0]);
                }}
                hidden
              />
              <AcInput
                size="small"
                header="Uploaded Payout report"
                placeholder={`${isEditMode ? 'File already uploaded' : 'No file chosen yet'}`}
                value={reportFile?.name || ''}
                disabled
              />
              <ActionButton
                variant="outlined"
                text={`${isEditMode ? 'Choose new file' : 'Choose file'}`}
                onClick={() => fileRef.current && fileRef.current.click()}
              />
            </Stack>
            <ActionButton
              text={`${isEditMode ? 'Edit' : 'Upload'} & Save`}
              onClick={isEditMode ? updateExistingPayout : createNewPayout}
            />
          </LocalizationProvider>
        </Stack>
      </Drawer>
    </AcViewWrapper>
  );
};

export default PayoutsTable;
