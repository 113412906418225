import { Box, IconButton, Stack, Typography } from '@mui/material';
import { permissionsUtil } from 'utils/permissionsUtil';
import { localStorageUtil } from 'utils/localStorageUtil';
import { ELocalStorageKeys } from 'constants/enums';
import SidebarDevDialog from '../SidebarDevDialog';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthSliceState } from 'store/store.types';
import useUsers from 'api/useUsers';
import useTheme from 'api/useTheme';
import AcAvatar from 'components/AcAvatar/AcAvatar';
import { User } from 'common/contracts';
import { useNavigate, useParams } from 'react-router-dom';
import { authActions } from 'store/authSlice';
import usePermissions from 'api/usePermissions';

interface SidebarHeaderProps {
    isSidebarOpen: boolean;
    setIsBackBtnShown:  Dispatch<SetStateAction<boolean>>;
    isBackBtnShown: boolean
}

const SidebarHeader = ({isSidebarOpen, setIsBackBtnShown, isBackBtnShown}: SidebarHeaderProps) => {
    const [showEnvPicker, setShowEnvPicker] = useState<boolean>(false);
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { logout } = useUsers({ publisherId: currentPublisherId, enableFeatureFlags: false });
    const { getStoreTheme } = useTheme(currentPublisherId);
    const { id } = useParams();
    const { getPermissions } = usePermissions();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const projectsDetails = localStorageUtil.getAny<User>(
        ELocalStorageKeys.USER_DETAILS
    )?.projects;

    const projectItem = projectsDetails?.find(
        (project) => project.publisherId === id
    );

    const allowedPublishers =
        permissionsUtil.getAccessPublisherOrders(getPermissions());

    const getCompanyName = () => {
        if (!currentPublisherId || !isBackBtnShown) {
            return localStorageUtil.getAny<User>(ELocalStorageKeys.USER_DETAILS)?.company;
        }
        return projectItem?.publisherName;
    };

    const handleBackBtnClick = () => {
        setIsBackBtnShown(false);
        let navigationPath = '/project';
        if (allowedPublishers?.length > 0) {
            navigationPath = '/project/orders';
        }
        navigate(navigationPath);
        dispatch(
            authActions.setCurrentPublisherId({
                currentPublisherId: null
            })
        );
    };

    return (
      <>
        <div className="logoContainer">
           <img
             className="logo"
             alt="logo"
             src={`${process.env.PUBLIC_URL}/assets/images/logo_purple.svg`}
             onClick={(e) => {
               process.env.NODE_ENV === 'development' &&
               e.detail === 3 && setShowEnvPicker(!showEnvPicker);
             }}
           />
             {(process.env.NODE_ENV === 'development' ||
               process.env.ENVIRONMENT === 'Dev' ||
               process.env.ENVIRONMENT === 'Staging') && (
                 <span>
                   {process.env.ENVIRONMENT === 'Dev' ||
                    process.env.NODE_ENV === 'development'
                     ? 'DEVELOPMENT'
                     : 'STAGING'
                   }
                 </span>
             )}
             {currentPublisherId && (
                <Stack justifyContent="center" alignItems="center">
                  {!!permissionsUtil.getRoleByPublisherId(currentPublisherId) && (
                     <span className="role-tag">
                       {permissionsUtil.getRoleByPublisherId(currentPublisherId)}
                     </span>
                  )}
                </Stack>
             )}
             {localStorageUtil.get(ELocalStorageKeys.ENVIRONMENT) && (
                <span
                  className="api-env"
                  onClick={() => {
                    localStorageUtil.removeAny(ELocalStorageKeys.ENVIRONMENT);
                    window.location.reload();
                    logout.mutate();
                  }}
                >
                  API:{' '}
                  {localStorageUtil
                    .get(ELocalStorageKeys.ENVIRONMENT)
                    .match(/(?<=api-)[^.]+/)[0]
                  }
                  <br />
                  <span>click to reset</span>
                </span>
             )}
             {process.env.NODE_ENV === 'development' && (
               <SidebarDevDialog
                 showEnvPicker={showEnvPicker}
                 setShowEnvPicker={setShowEnvPicker}
               />
             )}
        </div>
        <Box width="96%" mb={-1}>
          <Box my={1}>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                gap={0.5}
                ml={1.25}
                my={0.5}
                className={
                   isSidebarOpen ? 'store-logo-open' : 'store-logo-closed'
                }
              >
                {isBackBtnShown && isSidebarOpen && (
                  <IconButton
                    className="back-button"
                    aria-label="back"
                    size="small"
                    onClick={handleBackBtnClick}
                  >
                     <ArrowBackIosIcon />
                  </IconButton>
                )}
                  {!isBackBtnShown ? (
                     <AcAvatar content={getCompanyName()?.charAt(0)} />
                    ) : (
                        <>
                          {!getStoreTheme.isLoading && (
                            <img
                              style={{display: 'none' }}
                              src={
                                !isBackBtnShown
                                   ? getStoreTheme.data?.general.logo
                                   : projectItem?.publisherLogo
                              }
                              alt="store logo"
                            />
                          )}
                        </>
                    )
                  }
                  <Typography fontFamily="'Montserrat', sans-serif">
                     {getCompanyName()}
                  </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </>
    );

}
export default SidebarHeader;
