import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
  ExtendedDateRange,
  ScheduleTimeFrame,
  TimeFrameTableData
} from 'common/contracts';
import { ENotificationType, EScheduleType } from 'constants/enums';
import { Dayjs } from 'dayjs';
import { useNotifications } from 'hooks/useNotifications';

import AcSwitchSelector from 'components/AcSwitchSelector/AcSwitchSelector';
import CustomizedSwitch from 'components/SwitchButton/SwitchButton';

import PermanentOffer from './PermanentOffer';
import ScheduledOffer from './ScheduledOffer';

const ScheduleTab = ({
  values,
  formikProps,
  updateOffer,
  edit,
  startHour,
  setStartHour
}: any) => {
  const [intervalCron, setIntervalCron] = useState<string>(
    values.schedule?.interval
  );
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [appearancesTableData, setAppearancesTableData] = useState<
    TimeFrameTableData[]
  >([]);
  const [editRangeDatesValues, setEditRangeDatesValues] =
    useState<ExtendedDateRange | null>(null);
  const [notes, setNotes] = useState('');

  const { setFieldValue } = formikProps;

  const { enqueueSnackbar } = useNotifications();

  useEffect(() => {
    const timeFramesData: TimeFrameTableData[] =
      values.schedule?.timeFrames?.map(
        (date: ScheduleTimeFrame, index: number) => ({
          id: date.id || `id-${index}`,
          startTime: date.startTime,
          endTime: date.endTime,
          notes: date.notes,
        })
      );

    setAppearancesTableData(timeFramesData);
  }, [values?.schedule?.timeFrames]);

  const updateOfferStatus = async (active: boolean) => {
    if (edit) {
      try {
        await updateOffer.mutateAsync({
          offerId: String(values?.offerId),
          form: { active: active }
        });
        setFieldValue('active', active);
      } catch (error) {
        enqueueSnackbar('Error editing special offer', ENotificationType.ERROR);
      }
    } else setFieldValue('active', active);
  };

  const switchOptions = [
    { value: EScheduleType.PERMANENT },
    { value: EScheduleType.SCHEDULED }
  ];

  const resetScheduleValues = () => {
    setDateRange([null, null]);
    setEditRangeDatesValues(null);
    setNotes('');
    setStartHour('');
    setIntervalCron('');
  };

  const onClickSwitch = (value: string) => {
    const isPermanent = value === EScheduleType.PERMANENT;
    setFieldValue('schedule', {
      timeFrames: values?.schedule.timeFrames,
      permanent: isPermanent,
      ...(intervalCron && isPermanent && { interval: intervalCron })
    });
    resetScheduleValues();
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <AcSwitchSelector
          options={switchOptions}
          onClick={onClickSwitch}
          value={values?.schedule?.permanent}
        />
        <CustomizedSwitch
          status={values.active}
          texts={['Is Active', 'Is Active']}
          functions={[
            () => updateOfferStatus(false),
            () => updateOfferStatus(true)
          ]}
          color={'#000000'}
        />
      </Stack>
      {values?.schedule?.permanent ? (
        <PermanentOffer
          formikProps={formikProps}
          startHour={startHour}
          setStartHour={setStartHour}
          intervalCron={intervalCron}
          setIntervalCron={setIntervalCron}
        />
      ) : (
        <ScheduledOffer
          formikProps={formikProps}
          editRangeDatesValues={editRangeDatesValues}
          setEditRangeDatesValues={setEditRangeDatesValues}
          intervalCron={intervalCron}
          appearancesTableData={appearancesTableData}
          notes={notes}
          setNotes={setNotes}
          dateRange={dateRange}
          setDateRange={setDateRange}
          resetScheduleValues={resetScheduleValues}
        />
      )}
    </Stack>
  );
};

export default ScheduleTab;
