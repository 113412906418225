import { SyntheticEvent, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TabsPanel } from 'design-system/TabPanel/TabPanel';
import useIntegration from 'api/useIntegration';
import usePublisher from 'api/usePublisher';
import usePermissions from 'api/usePermissions';
import { EPermissionGroup } from 'common/permissions.types';
import { EPublisherSolutionModel, User } from 'common/contracts';
import { ELocalStorageKeys } from 'constants/enums';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';
import { permissionsUtil } from 'utils/permissionsUtil';
import PageTopBar from '../Topbar/PageTopBar';
import { ESettingsState } from './Settings.types';
import { settingsTabUtils } from './utils';

import './style.scss';

const SettingsView = () => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const projectsDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.projects;
  const { getPublisher, updatePublisher } = usePublisher(
    currentPublisherId || undefined
  );
  const { getIntegration, updateIntegration, getPublisherEvents }: any =
    useIntegration(currentPublisherId);
  const { getPermissions } = usePermissions();
  const permissions = getPermissions();
  const navigate = useNavigate();

  const projectItem = projectsDetails?.find(
      (item) => item.publisherId === currentPublisherId
  );
  const isCheckout = projectItem?.projectType === EPublisherSolutionModel.CHECKOUT

  const handleAuthTabShown = () => {
    return (
      !isCheckout ||
      permissionsUtil.shouldHideGroupInPermission(
        permissions,
        EPermissionGroup.AUTHENTICATION,
        getPublisher.data?._id
      )
    );
  };

  const [tab, setTab] = useState(
    settingsTabUtils.getActiveSettingsTab(
      permissions,
      getPublisher.data?._id,
      isCheckout
    )
  );

  const handleSettingsTabShown = () => {
    return currentPublisherId
      ? permissionsUtil.isSuperAdminByProject(currentPublisherId)
      : false;
  };

  const getTabs = useMemo(() => {
    const tabs = [];
    const isCompanyTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.COMPANY,
      getPublisher.data?._id
    );
    const isUsersTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.USERS,
      getPublisher.data?._id
    );
    const isIntegrationsTabShown = !permissionsUtil.shouldHideGroupInPermission(
      permissions,
      EPermissionGroup.INTEGRATION,
      getPublisher.data?._id
    )

    if (isCompanyTabShown) {
      tabs.push({label:"Company", value: ESettingsState.COMPANY})
    }

    if (isUsersTabShown) {
      tabs.push({label:"Users", value: ESettingsState.USERS})
    }

    if (isIntegrationsTabShown) {
      tabs.push({label:"Integration", value: ESettingsState.INTEGRATION})
    }

    if (handleAuthTabShown()) {
      tabs.push({label:"Authentication", value: ESettingsState.PLAYER_AUTH})
    }
    if (handleSettingsTabShown()) {
      tabs.push(
        {label:"Payments", value: ESettingsState.PAYMENTS},
        {label:"Store Layout", value: ESettingsState.LAYOUT},
        {label:"Store Settings", value: ESettingsState.SETTINGS}
      )
    }
    return tabs;

  }, [permissions, getPublisher.data?._id, handleAuthTabShown, handleSettingsTabShown])

  const handleTabChange = (event:  SyntheticEvent, newValue: ESettingsState) => {
    getIntegration.refetch();
    setTab(newValue);
    const newPath = `/project/${currentPublisherId}/settings/${newValue}`;
    navigate(newPath);
  }

  return (
    <TabsPanel
      tabs={getTabs}
      headerComponent = {<PageTopBar withTabsDesign={true} headline="Admin" />}
      handleTabChange={handleTabChange}
      activeTabVal={tab}
    />
  );
};

export default SettingsView;
