import { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';

export enum FilterType {
    SELECT = 'select',
    DATE_RANGE = 'date_range',
    SEARCH_INPUT = 'search_input'
}

export interface FilterConfig {
    type: FilterType;
    value?: any;
    name?: string;
    onChange?: (value: any) => void;
    setValue?: Dispatch<SetStateAction<string>>;
    header?: string;
    isCheckbox?: boolean;
    items?: Array<{ key: string; value: any; content: string }>;
    renderStatusTextOverride?: any;
    rangeDates?: [Dayjs, Dayjs]
    setRangeDates?: (dates?: ([Dayjs, Dayjs] | undefined)) => void;
    setSelectedItems?: (selectedItems: string[]) => void
}

export interface FilterBarProps {
    filters: FilterConfig[];
    handleClearFilters?: () => void;
    searchByKeyPlaceholder?: string;
    onSearchByKeyChange?: (value: string) => void;
    searchByKeyValue?: string
}
