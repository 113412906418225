import React, { FC, useEffect, useMemo, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import dayjs, { Dayjs } from 'dayjs';
import useBlockedPlayers from 'api/useBlockedPlayers';
import { FilterBar } from 'components/FilterBar/FilterBar';
import FirstActionModal from 'components/FirstActionModal/FirstActionModal';
import DataTable from 'components/DataTable/DataTable';
import { SortingDirection } from 'constants/enums';
import { BlockedPlayer, EBlockedPlayerStatus, SortingBlockedPlayersValue } from 'views/Players/Players.types';
import { blockedPlayersUtils } from './utils';
import { StyledBlockedPlayersTable } from './style';

export const BlockedPlayersTable: FC = () => {
    const { getBlockedPlayers: blockedPlayers } = useBlockedPlayers();
    const [rangeDates, setRangeDates] = useState<[Dayjs, Dayjs]>();
    const [reasonFilterValue, setReasonFilterValue] = useState('');
    const [statusFilterValues, setStatusFilterValues] = useState<string[]>([]);
    const [displayedBlockedPlayers, setDisplayedBlockedPlayers] = useState<BlockedPlayer[]>();
    const [searchByPlayerIdValue, setSearchByPlayerIdValue] = useState<string>('');
    const [sorting, setSorting] = useState<{
        sortValue: SortingBlockedPlayersValue;
        direction: SortingDirection;
    }>({
        sortValue: SortingBlockedPlayersValue.STATUS,
        direction: SortingDirection.ASC
    });
    const tableColumns = blockedPlayersUtils.blockedPlayersColumns;

    useEffect(() => {
      if (blockedPlayers?.data) {
        setDisplayedBlockedPlayers(blockedPlayers?.data)
      }
    }, [blockedPlayers?.data]);

    const getTableRows = useMemo(() =>
        (displayedBlockedPlayers?.map((playerData: BlockedPlayer) => ({_id: playerData.playerId, ...playerData}))),
    [displayedBlockedPlayers]);

    const filters = blockedPlayersUtils.getBlockedPlayersFilters(
      setRangeDates,
      statusFilterValues,
      setStatusFilterValues,
      setReasonFilterValue,
      reasonFilterValue,
      rangeDates
    );

    useEffect(() => {
      const filterBySearchKey = (data: BlockedPlayer[]) => {
        if (!searchByPlayerIdValue.length) return data;
        return data.filter((item) =>
          item.playerId.toLowerCase().includes(searchByPlayerIdValue.toLowerCase())
        );
      };
      const filterByDateRange = (data: BlockedPlayer[]) => {
        if (!rangeDates) return data;
        const [from, to] = rangeDates;
        return data.filter((item) => {
          const banDate = dayjs(item.banDate);
          return banDate.isSameOrAfter(from, 'day') &&
            banDate.isBefore(to, 'day') || banDate.isSame(to, 'day')
          ;
        });
      };
      const filterByStatus = (data: any[]) => {
        if (
          statusFilterValues.length === 0 ||
          statusFilterValues.length === Object.keys(EBlockedPlayerStatus).length
        )
        return data;
        const isBlockedFilter = statusFilterValues[0] === EBlockedPlayerStatus.BLOCKED;
        return data.filter((item) => item.isBlocked === isBlockedFilter);
      };

      const filterByReason = (data: any[]) => {
        if (!reasonFilterValue?.length) return data;
          return data.filter((item) =>
            item.banReason.toLowerCase().includes(reasonFilterValue.toLowerCase())
        );
      };
      const getFilteredData = () => {
        let baseData = searchByPlayerIdValue.length === 0 ? blockedPlayers?.data : displayedBlockedPlayers;
        baseData = filterBySearchKey(baseData);
        baseData = filterByDateRange(baseData);
        baseData = filterByStatus(baseData);
        baseData = filterByReason(baseData);
        return baseData;
      };

       setDisplayedBlockedPlayers(getFilteredData());

    }, [rangeDates, statusFilterValues, reasonFilterValue, searchByPlayerIdValue]);

    const handleClearFilters = () => {
      setReasonFilterValue('');
      setStatusFilterValues([]);
      setRangeDates(undefined);
      setDisplayedBlockedPlayers(blockedPlayers?.data);
      setSearchByPlayerIdValue('')
    }

    const areFiltersApplied = rangeDates ||
      searchByPlayerIdValue.length > 0 ||
      statusFilterValues.length > 0 ||
      searchByPlayerIdValue;

    const noDataAvailable = (!displayedBlockedPlayers || displayedBlockedPlayers?.length === 0) && !areFiltersApplied;

    const handleSortModelChange = (model: GridSortModel) => {
      if (model.length > 0) {
        setSorting({
          sortValue: model[0].field as SortingBlockedPlayersValue,
          direction:
            model[0].sort === SortingDirection.ASC
            ? SortingDirection.ASC
            : SortingDirection.DESC
        });
      }
    }

    return (
      <StyledBlockedPlayersTable>
        <FilterBar
          filters={filters}
          handleClearFilters={handleClearFilters}
          searchByKeyPlaceholder={"Search with player ID"}
          searchByKeyValue={searchByPlayerIdValue}
          onSearchByKeyChange={setSearchByPlayerIdValue}
        />
        <DataTable
          columns={tableColumns}
          rows={getTableRows|| []}
          loading={blockedPlayers.isLoading}
          error={blockedPlayers.isError}
          initialSorting={{
            sortModel: [
              {
                field: sorting.sortValue,
                sort:
                  sorting.direction === SortingDirection.ASC
                    ? SortingDirection.ASC
                    : SortingDirection.DESC
              }
            ]
          }}
          onSortModelChange={handleSortModelChange}
          onNoData={
              noDataAvailable ? (
              <FirstActionModal
                 headline="No blocked players yet"
                 text="Currently there are no blocked users for your store"
              />
            ) : (
              <FirstActionModal
                 headline="No exact matches"
                 text="Try changing or removing some of your filters or adjusting your search area"
              />
            )
          }
         />
      </StyledBlockedPlayersTable>
    )
}
