import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs';

import { Project } from 'common/contracts';
import ActionButton from 'components/ActionButton/ActionButton';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';

import { EReportStatus, financialReportEnumStatusMap } from './Report.types';

export const getColumns = (
  handleDownloadAction: (id: string) => void,
  projectsDetails?: Project[]
) => {
  const columns = [
    {
      field: 'timestamp',
      minWidth: 100,
      headerName: 'Created At',
      disableReorder: true,
      flex: 1,
      hideable: false,
      valueFormatter: ({ value }: any) => {
        return dayjs(value).utc().format('DD/MM/YY HH:mm:ss');
      }
    },
    {
      field: 'publisherName',
      headerName: 'Project',
      disableReorder: true,
      hideable: false,
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        const projectNames =
            params.row.publishers.length > 1 &&
            params.row.publishers.length === projectsDetails?.length
                ? 'All'
                : params.row.publishers
                    .map(
                        (item: string) =>
                            projectsDetails?.find(
                                (project) => project.publisherId === item
                            )?.publisherName
                    )
                    .join(',');
        return (
            <Tooltip arrow title={projectNames} placement="top">
              <span className="project-name-data">{projectNames}</span>
            </Tooltip>
        );
      }
    },
    {
      field: 'startDate',
      minWidth: 100,
      headerName: 'Start',
      disableReorder: true,
      flex: 1,
      hideable: false,
      valueGetter: (params: any) => {
        return dayjs(params.row.interval.startDate).utc().format('DD/MM/YY');
      }
    },
    {
      field: 'endDate',
      minWidth: 100,
      headerName: 'End',
      disableReorder: true,
      flex: 1,
      hideable: false,
      valueGetter: (params: any) => {
        return dayjs(params.row.interval.endDate).utc().format('DD/MM/YY');
      }
    },
    {
      field: 'status',
      minWidth: 100,
      headerName: 'Status',
      disableReorder: true,
      flex: 1,
      hideable: false,
      renderCell: ({ value }: any) => {
        return (
          <StatusLabel
            text={financialReportEnumStatusMap[value as EReportStatus]?.text}
            status={
              financialReportEnumStatusMap[value as EReportStatus]?.status
            }
            prefixIcon={
              financialReportEnumStatusMap[value as EReportStatus]?.prefixIcon
            }
          ></StatusLabel>
        );
      }
    },
    {
      field: 'download',
      minWidth: 200,
      headerName: 'Download Report',
      disableReorder: true,
      flex: 1,
      hideable: false,
      renderCell: ({ row }: any) => {
        const reportId = row.reportId;
        const status = row.status;
        return (
          <ActionButton
            variant="outlined"
            text={'Download'}
            disabled={status !== EReportStatus.CREATED}
            onClick={() => handleDownloadAction(reportId)}
            buttonClasses={'table-button'}
            icon={<DownloadOutlinedIcon />}
          />
        );
      }
    }
  ];
  return columns;
};

export const getPayoutsColumns = (
  handleDownloadAction: (
    id: string,
    publisherId?: string,
    period?: string
  ) => void,
) => {
  const columns = [
    {
      field: 'reference',
      headerName: 'Transfer Reference',
      disableReorder: true,
      hideable: false,
      flex: 1
    },
    {
      field: 'transferDate',
      headerName: 'Transfer Date',
      disableReorder: true,
      hideable: false,
      flex: 1,
      valueFormatter: ({ value }: any) => {
        return dayjs(value).utc().format('DD/MM/YY');
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      disableReorder: true,
      hideable: false,
      flex: 1,
      valueFormatter: ({ value }: any) => {
        return value.toLocaleString();
      }
    },
    {
      field: 'currency',
      headerName: 'Currency',
      disableReorder: true,
      hideable: false,
      flex: 1
    },
    {
      field: 'period',
      headerName: 'Period',
      disableReorder: true,
      hideable: false,
      flex: 1,
      valueGetter: (params: any) => {
        return `${dayjs(params.row.period.startDate)
          .utc()
          .format('DD/MM/YY')} - ${dayjs(params.row.period.endDate)
          .utc()
          .format('DD/MM/YY')}`;
      }
    },
    {
      field: 'download',
      minWidth: 200,
      headerName: 'Download Report',
      disableReorder: true,
      hideable: false,
      flex: 1,
      renderCell: ({ row }: any) => {
        const payoutId = row._id;
        const publisherId = row?.publisherId;
        const period = `${dayjs(row.period.startDate)
          .utc()
          .format('DD/MM/YY')} - ${dayjs(row.period.endDate)
          .utc()
          .format('DD/MM/YY')}`;
        return (
          <ActionButton
            variant="outlined"
            text={'Download'}
            onClick={() => handleDownloadAction(payoutId, publisherId, period)}
            buttonClasses={'table-button'}
            icon={<DownloadOutlinedIcon />}
          />
        );
      }
    }
  ];
  return columns;
};
