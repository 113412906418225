import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Stack } from '@mui/material';
import usePermissions from 'api/usePermissions';

import usePublisher from 'api/usePublisher';
import useTheme from 'api/useTheme';
import useUsers from 'api/useUsers';
import { EPublisherSolutionModel, User } from 'common/contracts';
import {
  EBundlesInternalViewModel,
  ELocalStorageKeys
} from 'constants/enums';
import { authActions } from 'store/authSlice';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';

import { SidebarProps } from './Sidebar.types';
import SidebarMenu from './Menu/SidebarMenu';
import { SidebarMenuItem } from './MenuOptionsGroup/SidebarMenuOptionsGroup.types';
import SidebarProfile from './Profile/SidebarProfile';
import { getMainNavItems, getSubNavItems } from './utils';
import SidebarHeader from './Header/SidebarHeader';
import { SidebarSkeleton } from './Skeleton/SidebarSkeleton';

import './style.scss';

const Sidebar = ({ updateMainSize, isSidebarOpen }: SidebarProps) => {
  const [isNewReport, setIsNewReport] = useState<boolean>(false);

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const navigate = useNavigate();
  const [status, setStatus] = useState<string>('');
  const { getStoreTheme } = useTheme(currentPublisherId);
  const [isProductMadness, setIsProductMadness] = useState<boolean>(false);
  const [isBackBtnShown, setIsBackBtnShown] = useState(false);
  const [navItems, setNavItems] = useState<SidebarMenuItem[]>([]);
  const { getPermissions } = usePermissions();
  const dispatch = useDispatch();
  const { getPublisher } = usePublisher(currentPublisherId || undefined);
  const { id } = useParams();
  const location = useLocation();

  const projectsDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.projects;

  const projectItem = projectsDetails?.find(
    (project) => project.publisherId === id
  );

  const isProjectCheckout = projectItem?.projectType === EPublisherSolutionModel.CHECKOUT;

  const { fetchFeatureFlags } = useUsers({ publisherId: currentPublisherId });
  const featureFlags = fetchFeatureFlags.data?.featureFlags;
  const permissions = getPermissions();

  useEffect(() => {
    const bundleViewModel =
      getStoreTheme?.data?.general?.bundlesInternalViewModel;
    setIsProductMadness(
      [
        EBundlesInternalViewModel.JACKPOT_SLOTS,
        EBundlesInternalViewModel.BIG_FISH_CASINO
      ].includes(bundleViewModel)
    );
  }, [getStoreTheme?.data?.general?.bundlesInternalViewModel]);

  useEffect(() => {
    if (!isBackBtnShown) {
      setNavItems(getMainNavItems(projectsDetails, isNewReport, permissions));
    }

    if (projectItem && id) {
      dispatch(
          authActions.setCurrentPublisherId({
            currentPublisherId: id
          })
      );
      setNavItems(
         getSubNavItems(
            id,
            featureFlags,
            permissions,
            isProjectCheckout
         )
      );
      setIsBackBtnShown(true);
    }
  }, [
    navigate,
    isBackBtnShown,
    id,
    fetchFeatureFlags.data,
    currentPublisherId
  ]);

  useEffect(() => {
    if (id) {
      dispatch(
        authActions.setCurrentPublisherId({
          currentPublisherId: id
        })
      );
    }
  }, [location, id]);

  const toggleSideBar = () => {
    setStatus(isSidebarOpen ? 'closed' : '');
    updateMainSize(!isSidebarOpen);
  };

  const shouldContentBeShown = useMemo(() => {
    return !getStoreTheme.isLoading ||
      !getStoreTheme.isFetching ||
      !getPublisher.isFetching ||
      !getPublisher.isLoading
  }, [
      getStoreTheme.isLoading,
      getStoreTheme.isFetching,
      getPublisher.isFetching,
      getPublisher.isLoading
  ])

  return (
    <div id="sideNav" className={status}>
      <div className="arrow-container" onClick={toggleSideBar}>
        <ArrowBackIosRoundedIcon className="arrow" />
      </div>
      <Stack
        style={{
          height: '100%',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <Stack
          style={{
            height: 'calc(100% - 100px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          {shouldContentBeShown ? (
            <>
              <SidebarHeader
                 isSidebarOpen={isSidebarOpen}
                 isBackBtnShown={isBackBtnShown}
                 setIsBackBtnShown={setIsBackBtnShown}
              />
              {navItems?.length > 0 && (
                 <SidebarMenu navItems={navItems}/>
              )}
            </>
          ) : (
              <SidebarSkeleton />
          )}
        </Stack>
      </Stack>
      <div className="nav-profile-footer">
        <SidebarProfile />
      </div>
    </div>
  );
};

export default Sidebar;
