import styled from 'styled-components';

export const StyledImgSelect = styled.div `
  display: flex;
  flex-direction: column;
  .imgSelect-label {
    margin-bottom:  4px;
    font-size: 12px;  
  }
  .MuiOutlinedInput-root {
    padding: 5px 8px  
  }
  
`as any;
