import React, { FC } from 'react';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import AcSearchSelect from 'components/AcSelect/AcSearchSelect';
import { DateRangeFilter } from './components/DateRangeFilter';
import AcSearchInput from 'components/AcInput/AcSearchInput';
import AcInput from 'components/AcInput/AcInput';
import { FilterBarProps, FilterConfig, FilterType } from './types';

const mapFilterLayout: { [key in FilterType]: (filter: FilterConfig) => JSX.Element | null } = {
  [FilterType.SELECT]: (filter) =>
    <AcSearchSelect
      items={filter?.items || []}
      sx={{ flexGrow: 1 }}
      value={filter.value}
      {...filter}
    />,
  [FilterType.DATE_RANGE]: (filter) =>
    <DateRangeFilter
      rangeDates={filter?.rangeDates}
      header={"Block Date"}
      {...filter}
    />,
  [FilterType.SEARCH_INPUT]: (filter) => {
    if (!filter?.setValue) return null;
    return <AcSearchInput size="small" setValue={filter.setValue} value={filter.value} {...filter} />
  }
}

export const FilterBar: FC<FilterBarProps> = ({
  filters,
  handleClearFilters,
  searchByKeyPlaceholder ,
  onSearchByKeyChange,
  searchByKeyValue,
}) => {
  const isSearchByKeyEnabled = searchByKeyPlaceholder && onSearchByKeyChange;
  return (
    <Stack direction="column">
      {isSearchByKeyEnabled && (
        <AcInput
          placeholder={searchByKeyPlaceholder}
          value={searchByKeyValue}
          onChange={(e) => onSearchByKeyChange(e.target.value)}
          sx={{
            maxWidth: '340px',
            marginTop: '16px'
          }}
          customClass="filterBar-searchByKey"
        />
      )}
      <Stack direction="row" gap={1} mt={2}>
        {filters.map((filter, index) => (
          <div key={index}>
            {mapFilterLayout[filter.type]?.(filter)}
          </div>
        ))}
        {handleClearFilters && (
          <Button
            variant="text"
            id="clearFiltersButton"
            onClick={handleClearFilters}
          >
            Clear filters
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
