import { SyntheticEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageTopBar from 'components/Topbar/PageTopBar';
import { TabsPanel } from 'design-system/TabPanel/TabPanel';

import '../view.scss';

enum EReportsTabs {
  FINANCIAL = 'financialreports',
  PAYOUTS = 'payouts'
}

const ReportsView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTab = location.pathname.split('/')[3];
  const [activeTab, setActiveTab] = useState(currentTab);

  const handleTabChange = (e: SyntheticEvent, value: string) => {
    setActiveTab(value);
    const basePath = location.pathname.split(currentTab)[0];
    navigate(`${basePath}${value}`);
  };

  const tabs = [
    {label: "Financial", value: EReportsTabs.FINANCIAL},
    {label: "Payouts", value: EReportsTabs.PAYOUTS}
  ];

  return (
    <TabsPanel
      tabs={tabs}
      headerComponent = {<PageTopBar withTabsDesign={true} headline="Reports" />}
      handleTabChange={handleTabChange}
      activeTabVal={activeTab}
    />
  );
};

export default ReportsView;
