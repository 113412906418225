import {
  ActionButton,
  Background,
  CheckoutBox,
  MessagePage
} from '@appcharge/shared-ui';
import { Divider, Grid, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

import { EStyleProperty } from 'constants/enums';
import { getStyledSelectItem } from 'utils/getStyledSelectItem';
import AcCard from 'components/AcCard/AcCard';
import AcGradientInput from 'components/AcGradientInput/AcGradientInput';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { ThemeFormValues } from './theme.types';
import 'style/forms.scss';

const SuccessTheme = () => {
  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    errors,
    touched,
  } = useFormikContext<ThemeFormValues>();
  return (
    <Grid container className="formContent">
      <Grid item xs={5}>
        <Stack gap={3}>
          <AcCard
            stackContainer={false}
            title="Confirm the purchase"
            description="Congratulate your players on a successful purchase and let them know what’s next"
          >
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  header="Header"
                  name="completedScreen.headerText"
                  defaultValue={values.completedScreen.headerText}
                  tooltip="This will be the header of the success screen."
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.headerText &&
                    Boolean(errors.completedScreen?.headerText)
                  }
                  helperText={
                    touched.completedScreen?.headerText
                      ? errors.completedScreen?.headerText?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcGradientInput
                  header="Color"
                  name="completedScreen.headerColor"
                  defaultValue={values.completedScreen.headerColor}
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.headerColor &&
                    Boolean(errors.completedScreen?.headerColor)
                  }
                  helperText={
                    touched.completedScreen?.headerColor
                      ? errors.completedScreen?.headerColor?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcInput
                  header="Size"
                  name="completedScreen.headerSize"
                  type="number"
                  inputProps={{
                    max: 40,
                    min: 26
                  }}
                  value={values.completedScreen.headerSize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.headerSize &&
                    Boolean(errors.completedScreen?.headerSize)
                  }
                  helperText={
                    touched.completedScreen?.headerSize
                      ? errors.completedScreen?.headerSize?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcSelect
                  header="Weight"
                  name="completedScreen.headerWeight"
                  defaultValue="normal"
                  value={values.completedScreen.headerWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.headerWeight &&
                    Boolean(errors.completedScreen?.headerWeight)
                  }
                  items={[
                    {
                      content: 'Regular',
                      key: 'regular',
                      value: '300',
                      renderFunction: () =>
                        getStyledSelectItem(
                          'regular',
                          EStyleProperty.fontWeight
                        )
                    },
                    {
                      content: 'Medium',
                      key: 'medium',
                      value: '500',
                      renderFunction: () =>
                        getStyledSelectItem('medium', EStyleProperty.fontWeight)
                    },
                    {
                      content: 'Bold',
                      key: 'bold',
                      value: '700',
                      renderFunction: () =>
                        getStyledSelectItem('bold', EStyleProperty.fontWeight)
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </AcCard>
          <AcCard stackContainer={false} title="Additional text">
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  header="Text"
                  name="completedScreen.text"
                  value={values.completedScreen.text}
                  tooltip=""
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.text &&
                    Boolean(errors.completedScreen?.text)
                  }
                  helperText={
                    touched.completedScreen?.text
                      ? errors.completedScreen?.text?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcGradientInput
                  header="Color"
                  name="completedScreen.textColor"
                  defaultValue={{
                    colorOne: values.completedScreen.textColor
                  }}
                  setValue={setFieldValue}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.textColor &&
                    Boolean(errors.completedScreen?.textColor)
                  }
                  helperText={
                    touched.completedScreen?.textColor
                      ? errors.completedScreen?.textColor?.toString()
                      : ''
                  }
                  outputAsSingleColor
                  onlySolid
                />
              </Grid>
              <Grid item xs={4}>
                <AcInput
                  header="Size"
                  name="completedScreen.textSize"
                  type="number"
                  inputProps={{
                    max: 24,
                    min: 12
                  }}
                  value={values.completedScreen.textSize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.textSize &&
                    Boolean(errors.completedScreen?.textSize)
                  }
                  helperText={
                    touched.completedScreen?.textSize
                      ? errors.completedScreen?.textSize?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <AcSelect
                  header="Weight"
                  name="completedScreen.textWeight"
                  defaultValue="normal"
                  value={values.completedScreen.textWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.textWeight &&
                    Boolean(errors.completedScreen?.textWeight)
                  }
                  items={[
                    {
                      content: 'Regular',
                      key: 'regular',
                      value: '300',
                      renderFunction: () =>
                        getStyledSelectItem(
                          'regular',
                          EStyleProperty.fontWeight
                        )
                    },
                    {
                      content: 'Medium',
                      key: 'medium',
                      value: '500',
                      renderFunction: () =>
                        getStyledSelectItem('medium', EStyleProperty.fontWeight)
                    },
                    {
                      content: 'Bold',
                      key: 'bold',
                      value: '700',
                      renderFunction: () =>
                        getStyledSelectItem('bold', EStyleProperty.fontWeight)
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Divider />
        <Stack gap={3}>
          <AcCard stackContainer={false} title="Back to Game Button">
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcInput
                  headerSize="14"
                  header="Back to Game Button Text"
                  name="completedScreen.backToGameButtonText"
                  value={values.completedScreen.backToGameButtonText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.completedScreen?.backToGameButtonText &&
                    Boolean(errors.completedScreen?.backToGameButtonText)
                  }
                  helperText={
                    touched.completedScreen?.backToGameButtonText
                      ? errors.completedScreen?.backToGameButtonText?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  headerSize="14"
                  header="Android Back to Game Button Deep Link"
                  name="integration.deepLinks.android"
                  value={values.integration.deepLinks.android}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.integration?.deepLinks?.android &&
                    Boolean(errors.integration?.deepLinks?.android)
                  }
                  helperText={
                    touched.integration?.deepLinks?.android
                      ? errors.integration?.deepLinks?.android?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  headerSize="14"
                  header="Apple Back to Game Button Deep Link"
                  name="integration.deepLinks.ios"
                  value={values.integration.deepLinks.ios}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.integration?.deepLinks?.ios &&
                    Boolean(errors.integration?.deepLinks?.ios)
                  }
                  helperText={
                    touched.integration?.deepLinks?.ios
                      ? errors.integration?.deepLinks?.ios?.toString()
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AcInput
                  headerSize="14"
                  header="Web Back to Game Button Deep Link"
                  name="integration.deepLinks.web"
                  value={values.integration.deepLinks.web}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.integration?.deepLinks?.web &&
                    Boolean(errors.integration?.deepLinks?.web)
                  }
                  helperText={
                    touched.integration?.deepLinks?.web
                      ? errors.integration?.deepLinks?.web?.toString()
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
      </Grid>
      <Grid item xs={7} className="iphone-mock-wrapper">
        <div className="store-preview-wrapper iphone-mock">
          <Background
            backgroundImageDesktop={values.general.backgroundImageMobile}
            backgroundImageMobile={values.general.backgroundImageMobile}
            width="100%"
            height="100%"
            position="absolute"
          >
            <MessagePage
              headerText={values.completedScreen.headerText}
              headerColor={values.completedScreen.headerColor}
              headerSize={values.completedScreen.headerSize}
              headerWeight={values.completedScreen.headerWeight}
              text={[values.completedScreen.text]}
              textColor={values.completedScreen.textColor}
              textSize={values.completedScreen.textSize}
              textWeight={values.completedScreen.textWeight}
              fontFamily={values.general.font}
              preview={true}
            >
              <CheckoutBox height="135px" width="90%">
                <div></div>
              </CheckoutBox>
              <ActionButton
                action={() => null}
                text={values.completedScreen.backToGameButtonText}
                colors={values.general.buttonColor}
                textColor={values.general.buttonTextColor}
              />
            </MessagePage>
          </Background>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessTheme;
