import React from 'react';

import AppleLogin from 'react-apple-login';

import { LoginButton } from '@appcharge/shared-ui';

import { FacebookLogin } from './FacebookLogin';
import { GoogleLogin } from './GoogleLogin';
import { ProvidersModeProps } from './types';

export const ProvidersMode: React.FC<ProvidersModeProps> = ({
  playerAuthData,
  translations,
  otpButton
}) => {
  return (
    <>
      {otpButton && (
        <LoginButton
          textColor={otpButton?.textColor}
          iconSrc={otpButton?.icon}
          iconSize={'32px'}
          backgroundColor={otpButton?.backgroundColor}
          text={otpButton?.text}
          callback={() => {}}
          dataTestId="otp-login-button"
        />
      )}
      <FacebookLogin translations={translations} />
      <GoogleLogin translations={translations} />
      <AppleLogin
        clientId={playerAuthData.appleAppId}
        redirectURI={`${window.location.href}?apple=true`}
        responseType={playerAuthData.appleResponseType}
        responseMode={'query'}
        callback={() => {}}
        render={(renderProps) => (
          <LoginButton
            variant="apple"
            callback={() => {}}
            text={translations?.signInWithApple || 'Sign in with Apple'}
          >
            <img
              src="https://media.appcharge.com/defaults/apple_icon_white.svg"
              alt="apple icon"
            />
          </LoginButton>
        )}
      />
      <LoginButton
        variant="transparent"
        text={translations?.loginWithUsername || 'Login with Username'}
        callback={() => {}}
      />
    </>
  );
};
