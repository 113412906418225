import { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { Decimal } from 'decimal.js';

import usePricing, { CurrencyDetails, GeoItem } from '../../api/usePricing';
import { MIN_USD_PRICE, MAX_USD_PRICE } from '../../constants/constants';
import { ENotificationType } from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { AuthSliceState } from '../../store/store.types';
import { errorResponse } from '../../utils/errorsTextHelper';
import { permissionsUtil } from '../../utils/permissionsUtil';
import AcCard from '../AcCard/AcCard';
import AcInput from '../AcInput/AcInput';
import AcViewWrapper from '../AcViewWrapper/AcViewWrapper';
import DataTable from '../DataTable/DataTable';
import PageTopBar from '../Topbar/PageTopBar';

import { AVAILABLE_COUNTRIES } from './Pricing.constants';
import { CustomPricing, PricingMeta } from './Pricing.types';
import PricingTableInputCell from './PricingTableInputCell';

enum TAX_TYPE {
  TAX_INCLUDED = 'taxIncluded',
  TAX_EXCLUDED = 'taxExcluded'
}

const PricingForm = () => {
  const isManuallyUpdatedListFilled = useRef(false);
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const navigate = useNavigate();
  const { pricePointId } = useParams();
  const {
    getGeo,
    getPricePoint,
    getPricings,
    calculatePricing,
    updatePricing,
    addPricing
  } = usePricing(currentPublisherId, pricePointId);
  const [currentPricePoint, setCurrentPricePoint] = useState<Decimal>(
    new Decimal(MIN_USD_PRICE)
  );
  const [isPricePointTaken, setIsPricePointTaken] = useState(false);
  const [prices, setPrices] = useState<CustomPricing[]>([]);
  const manuallyUpdatedPricesByCountryCode3 = useMemo(() => {
    if (!prices.length) return [];
    isManuallyUpdatedListFilled.current = true;
    return prices
      ?.filter((price: CustomPricing) => price.manuallyUpdated)
      .map((p: CustomPricing) => p.countryCode3);
  }, [prices]);
  const { enqueueSnackbar } = useNotifications();
  const [isDirty, setIsDirty] = useState(false);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const DEBOUNCE_DELAY = 1000;

  const checkoutIsPricePointTaken = () => {
    return getPricings.data?.result.find((pricePoint: PricingMeta) =>
      new Decimal(pricePoint.priceInCents).eq(currentPricePoint.mul(100))
    );
  };

  const handlePricePointChange = () => {
    if (checkoutIsPricePointTaken()) {
      setIsPricePointTaken(true);
      return;
    }

    if (!isManuallyUpdatedListFilled.current && pricePointId) return;
    setIsPricePointTaken(false);
    const anchors = [currentPricePoint.mul(100).toNumber()];

    calculatePricing.mutate(anchors, {
      onSuccess: (data: any) => {
        const newPrices = data.result[0].customPricing.map(
          (price: CustomPricing) => {
            if (
              manuallyUpdatedPricesByCountryCode3.includes(price.countryCode3)
            ) {
              return prices.find(
                (p: CustomPricing) => price.countryCode3 === p.countryCode3
              );
            } else return price;
          }
        );
        setPrices(newPrices);
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          enqueueSnackbar(errorResponse(error), ENotificationType.ERROR);
        }
      }
    });
  };

  const getCountryByCode = (code: string) => {
    return getGeo.data?.find(
      (geo: GeoItem) => geo.countryCode3 === code.toUpperCase()
    );
  };

  const updateCountryPriceManually = (
    newPrice: Decimal,
    countryCode3: string
  ) => {
    const newPrices = [...prices];

    for (let i = 0; i < newPrices.length; i++) {
      if (newPrices[i].countryCode3 === countryCode3) {
        newPrices[i].price = newPrice;
        newPrices[i].manuallyUpdated = true;
        newPrices[i].fresh = false;
        break;
      }
    }
    setIsDirty(true);
    setPrices(newPrices);
  };

  const getFormattedCurrency = (params: any, getBasePrice = false): number => {
    const multiplier = params.row.multiplier;
    const customPrice = new Decimal(params.row.price)
      .div(multiplier)
      .toNumber();

    // find the rate of the currency
    let rate = params.row.rate || 1;
    rate = new Decimal(rate).mul(100).div(multiplier).toNumber();

    const priceFromPoint = currentPricePoint.mul(rate).toNumber();

    return getBasePrice || !customPrice
      ? new Decimal(params.row.price).div(multiplier).toNumber()
      : customPrice || priceFromPoint;
  };

  const calculateMinPrice = (
    params: any,
    countryDetails?: CurrencyDetails
  ): string => {
    if (countryDetails) {
      const value = new Decimal(countryDetails.minChargeAmount || 0).div(
        countryDetails.multiplier || 1
      );
      return countryDetails.multiplier === 1
        ? value.toFixed(0)
        : value.toFixed(2);
    }
    return getFormattedCurrency(params, true).toString();
  };

  const calculateMaxPrice = (countryDetails?: CurrencyDetails): string => {
    if (countryDetails) {
      const value = new Decimal(
        countryDetails.maxChargeAmount || Number.MAX_SAFE_INTEGER
      ).div(countryDetails.multiplier || 1);
      return countryDetails.multiplier === 1
        ? value.toFixed(0)
        : value.toFixed(2);
    }
    return Number.MAX_SAFE_INTEGER.toString();
  };

  const formatPrices = () => {
    return prices.map((price: CustomPricing) => {
      return {
        countryCode3: price.countryCode3.toUpperCase(),
        currencyCode: price.currencyCode,
        price: new Decimal(
          price.countryCode3.toUpperCase() === 'ISL'
            ? price.price.toFixed(0)
            : price.price
        ),
        manuallyUpdated: price.manuallyUpdated || false
      } as CustomPricing;
    });
  };

  const handleAddPricing = () => {
    const formattedPrices = formatPrices();
    addPricing.mutate(
      {
        priceInCents: currentPricePoint.mul(100),
        customPricing: formattedPrices
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            'Pricing created successfully',
            ENotificationType.SUCCESS
          );
          navigate('../pricing/pricingPoints');
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            enqueueSnackbar(errorResponse(error), ENotificationType.ERROR);
          }
        }
      }
    );
  };

  const handleEditPricing = () => {
    if (!pricePointId) return;
    const formattedPrices = formatPrices();
    const pricingObject = {
      priceInCents: currentPricePoint.mul(100),
      customPricing: formattedPrices,
      _id: pricePointId
    };
    updatePricing.mutate(pricingObject, {
      onSuccess: () => {
        enqueueSnackbar(
          'Pricing updated successfully',
          ENotificationType.SUCCESS
        );
        navigate('../pricing/pricingPoints');
      },
      onError: (error: any) => {
        if (error instanceof AxiosError) {
          enqueueSnackbar(errorResponse(error), ENotificationType.ERROR);
        }
        navigate('../pricing/pricingPoints');
      }
    });
  };

  useEffect(() => {
    if (!pricePointId) {
      setCurrentPricePoint(new Decimal(MIN_USD_PRICE));
    } else if (!getPricePoint.isFetching || !getPricePoint.isLoading) {
      getPricePoint.refetch();
    }
  }, [pricePointId]);

  useEffect(() => {
    if (!getPricings.data) getPricings.refetch();
  }, []);

  useEffect(() => {
    if (!getPricePoint.data) return;
    setCurrentPricePoint(
      new Decimal(getPricePoint.data.result.priceInCents).div(100)
    );
    setPrices(getPricePoint.data.result.customPricing);
  }, [getPricePoint.data]);

  useEffect(() => {
    if (
      currentPricePoint &&
      currentPricePoint.greaterThanOrEqualTo(new Decimal(MIN_USD_PRICE))
    ) {
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

      debounceTimeout.current = setTimeout(() => {
        handlePricePointChange();
      }, DEBOUNCE_DELAY);

      return () => {
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
      };
    }
  }, [currentPricePoint]);

  return (
    <AcViewWrapper
      header={
        <PageTopBar
          disable={false}
          headline={pricePointId ? 'Edit Pricing' : 'New Pricing'}
          buttons={[
            {
              text: 'Save',
              action: () =>
                pricePointId ? handleEditPricing() : handleAddPricing(),
              disabled:
                !isDirty ||
                !permissionsUtil.canUserEdit() ||
                (isPricePointTaken && !pricePointId) ||
                currentPricePoint?.lt(MIN_USD_PRICE) ||
                currentPricePoint?.gt(MAX_USD_PRICE)
            }
          ]}
          backFunction={() => navigate('../pricing/pricingPoints')}
        />
      }
    >
      <Stack
        sx={{
          paddingTop: '24px',
          paddingLeft: '24px',
          paddingRight: '24px'
        }}
      >
        <AcCard>
          <Typography fontFamily="'Montserrat', sans-serif" fontWeight={600}>
            USD Price Point
          </Typography>
          <Stack gap={2}>
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <Grid item xs={5}>
                <Stack gap={2}>
                  <Grid item xs={12}>
                    <AcInput
                      value={currentPricePoint}
                      type="number"
                      onChange={(e) => {
                        setIsDirty(true);
                        setCurrentPricePoint(new Decimal(e.target.value || 0));
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        inputProps: {
                          min: MIN_USD_PRICE,
                          max: MAX_USD_PRICE
                        }
                      }}
                      error={
                        (isPricePointTaken && !pricePointId) ||
                        currentPricePoint?.lt(MIN_USD_PRICE) ||
                        currentPricePoint?.gt(MAX_USD_PRICE)
                      }
                      helperText={
                        currentPricePoint?.lt(MIN_USD_PRICE)
                          ? `Price point can't be lower than $${MIN_USD_PRICE}`
                          : currentPricePoint?.gt(MAX_USD_PRICE)
                          ? "Price point can't be higher than $5000"
                          : isPricePointTaken && !pricePointId
                            ? 'There is already a price point with this value'
                            : ''
                      }
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid>

            <Box mt={-2}>
              <DataTable
                rowIdIdentifier="countryCode3"
                columns={[
                  {
                    field: 'country',
                    headerName: 'Country',
                    disableReorder: true,
                    flex: 1,
                    renderCell: (params: any) => {
                      return (
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          gap={1}
                        >
                          <img
                            src={`./assets/flags/${(
                              AVAILABLE_COUNTRIES.find(
                                (country) =>
                                  country._id === params.id.toLowerCase()
                              )?.twoLetterId || ''
                            ).toLowerCase()}.png`}
                            alt=""
                            style={{
                              maxWidth: 16
                            }}
                          />
                          {params.value}
                        </Stack>
                      );
                    }
                  },
                  {
                    field: 'currencyCode',
                    headerName: 'Currency',
                    disableReorder: true,
                    flex: 1,
                    renderCell: (params: any) => {
                      return (
                        <Typography variant="body2" color="textSecondary">
                          {params.value}
                        </Typography>
                      );
                    }
                  },
                  {
                    field: 'price',
                    headerName: 'Price',
                    disableReorder: true,
                    flex: 1,
                    renderCell: (params: any) => {
                      const countryDetails = getCountryByCode(
                        params.id.toUpperCase()
                      );
                      return (
                        <PricingTableInputCell
                          countryData={countryDetails}
                          onPriceUpdate={updateCountryPriceManually}
                          pricePoint={getFormattedCurrency(params)}
                          minPrice={calculateMinPrice(
                            params,
                            countryDetails?.currencyDetails
                          )}
                          maxPrice={calculateMaxPrice(
                            countryDetails?.currencyDetails
                          )}
                        />
                      );
                    }
                  },
                  {
                    field: 'taxModel',
                    headerName: 'Tax model',
                    disableReorder: true,
                    flex: 1,
                    renderCell: (params: any) => {
                      return params.row.taxModel === TAX_TYPE.TAX_INCLUDED
                        ? 'Included'
                        : 'Excluded';
                    }
                  },
                  {
                    field: 'taxRate',
                    headerName: 'Tax rate',
                    disableReorder: true,
                    flex: 1,
                    renderCell: (params: any) => {
                      return params.row.taxRate / 100 + '%';
                    }
                  },
                  {
                    field: 'status',
                    headerName: 'Status',
                    disableReorder: true,
                    flex: 1,
                    renderCell: (params: any) => {
                      const cp = prices.find(
                        (cp: CustomPricing) =>
                          cp.countryCode3 === params.id.toUpperCase()
                      );
                      return (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            color:
                              (cp && cp.manuallyUpdated) ||
                              params.row.manuallyUpdated
                                ? 'green'
                                : 'black'
                          }}
                        >
                          {(cp && cp.manuallyUpdated) ||
                          params.row.manuallyUpdated
                            ? 'Manually Updated'
                            : 'Default'}
                        </Typography>
                      );
                    },
                    sortComparator: (a, b, params1, params2) => {
                      const cpA = prices.find(
                        (cp: CustomPricing) =>
                          cp.countryCode3 === String(params1.id).toUpperCase()
                      );
                      const cpB = prices.find(
                        (cp: CustomPricing) =>
                          cp.countryCode3 === String(params2.id).toUpperCase()
                      );

                      const manuallyUpdatedA = cpA?.manuallyUpdated || false;
                      const manuallyUpdatedB = cpB?.manuallyUpdated || false;

                      if (manuallyUpdatedA && !manuallyUpdatedB) {
                        return -1;
                      }
                      if (!manuallyUpdatedA && manuallyUpdatedB) {
                        return 1;
                      }
                      return 0;
                    }
                  }
                ]}
                rows={prices.filter((row: any) => row.countryCode3 !== 'USA')}
                loading={
                  pricePointId
                    ? getPricePoint.isLoading || getPricePoint.isFetching
                    : calculatePricing.isLoading
                }
                rowHeight={75}
              />
            </Box>
          </Stack>
        </AcCard>
      </Stack>
    </AcViewWrapper>
  );
};

export default PricingForm;
